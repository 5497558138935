<template>
  <div class="flex xl:flex-col w-full lg:mt-6">
    <div class="flex flex-col lg:w-full font-arial">
      <div class="flex flex-col">
        <CadastreOfficiel :map="map"></CadastreOfficiel>
        <div class="text-promy-gray-600 font-bold text-xs mt-3">
          Utilitaires:
        </div>
        <div class="flex w-full">
          <ul class="w-full">
            <li
              v-for="(type, idx) in types"
              :key="idx"
              class="flex justify-between items-stretch py-3 my-2 bg-white rounded-lg"
            >
              <label
                :for="type.marker"
                class="cursor-pointer text-promy-gray-650 text-base pl-8 flex items-center"
                :style="'min-height: 24px; color:' + type.color"
                >{{ type.label }}</label
              >
              <Checkbox
                :id="type.marker"
                :val="type"
                v-model="selected"
                class="pr-10"
              />
            </li>
          </ul>
        </div>
        <infoItineraire class="sm:hidden"></infoItineraire>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import defaultmap from '@/mixins/dossierPromoteurs/defaultmap'
import Localisation from '@/mixins/dossierPromoteurs/Localisation'
import Mapbox from '@/mixins/mapbox'
import helpers from '@/mixins/helpers'
import axios from 'axios'
import sourcesAndLayers from '@/mixins/cadastre/sourcesAndLayers'
import infoItineraire from './infoItineraire'
export default {
  components: {
    infoItineraire,
  },
  props: {
    allParcelles: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      points: [],
      multiPt: {},
      temp_option: null,
      markers: new Map(),

      selected: [],
      allMarkersLocalisations: [],
      line_route: {},
      profiles: ['driving', 'walking'],
    }
  },
  mixins: [Mapbox, helpers, defaultmap, sourcesAndLayers, Localisation],
  computed: {
    ...mapGetters({
      map: 'defaultmap/map',
      localisations: 'dossierpromoteur/getLocalisation',
      types: 'poi/types',
      current_position: 'localisation/current_position',
      walking: 'localisation/walking',
      driving: 'localisation/driving',
      position_active: 'localisation/position_active',
      is_street: 'initMap/is_street',
    }),
  },
  methods: {
    sliceOption() {
      if (!this.position_active.label) return
      let vm = this
      var filtered = this.selected.filter(function (el) {
        return el.label != vm.position_active.label
      })
      let marker_exist = filtered.find((el) => {
        return el.marker === vm.position_active.marker
      })
      if (!this.isExist(marker_exist)) {
        this.remove_add_layer_route_line()
        vm.$store.commit('localisation/DRIVING', {})
        vm.$store.commit('localisation/WALKING', {})
        vm.$store.commit('localisation/POSITION_ACTIVE', {
          subCat: null,
          marker: null,
          label: null,
          key: null,
        })
      }
    },
    addMarkersSelected(item) {
      let vm = this
      item.keys.forEach((key) => {
        let localisations = this.getNestedObject(this.localisations, key)
        if (this.isExist(localisations)) {
          for (let [key, value] of Object.entries(localisations)) {
            let localisation_exist = item.subKeys.find(
              (subKey) => subKey.key === key,
            )

            if (vm.isExist(localisation_exist)) {
              value.forEach((infos, index) => {
                if (!infos.name) {
                  infos.name = 'N.C'
                }

                let coords = !Array.isArray(infos.coordonnee_gps)
                  ? infos.coordonnee_gps.split('(')[1].split(')')[0].split(' ')
                  : infos.coordonnee_gps
                coords = coords.map((i) => Number(i))
                let feature_point = vm.$turf.point(coords)
                feature_point.properties.key = key + '' + index
                feature_point.properties.marker = item.marker
                feature_point.properties.subCat = []
                feature_point.properties.subCat.push(localisation_exist.name)
                feature_point.properties.subMarker = localisation_exist.marker
                feature_point.properties.localisation_name = []
                feature_point.properties.localisation_name.push(infos.name)
                feature_point.properties.coords = coords

                vm.points.push(feature_point)
              })
            }
          }
        }
      })
      const distinct_point = vm.points.reduce(
        (accumulator, current) =>
          accumulator.some((item, index) => {
            let is_find =
              item.properties.coords.join() === current.properties.coords.join()
            if (is_find) {
              accumulator[index].properties.localisation_name.push(
                ...current.properties.localisation_name,
              )
              accumulator[index].properties.subCat.push(
                ...current.properties.subCat,
              )
            }
            return is_find
          })
            ? accumulator
            : [...accumulator, current],
        [],
      )

      vm.multiPt = vm.$turf.featureCollection(distinct_point)
    },

    addCluster() {
      if (this.isExist(this.map.getLayer(this.source_and_layers_cluster[1]))) {
        this.map.removeLayer(this.source_and_layers_cluster[1])
        this.map.removeSource(this.source_and_layers_cluster[0])
      }

      if (this.multiPt.features) {
        var feature_collection = this.$turf.featureCollection([
          ...this.multiPt.features,
          ...this.allParcelles,
        ])
        let polyToZoom = this.getPolygonForMaxZoom(feature_collection, 1)
        this.map.addSource(this.source_and_layers_cluster[0], {
          type: 'geojson',
          data: this.multiPt,
          generateId: true,
          cluster: true,
          clusterMaxZoom: 15,
          clusterRadius: 50,
        })

        this.map.addLayer({
          id: this.source_and_layers_cluster[1],
          type: 'circle',
          source: this.source_and_layers_cluster[0],
          filter: ['==', 'promy-cluster', true],
          paint: {
            'circle-color': 'white',
            'circle-radius': 12,
            'circle-opacity': 0,
          },
        })
        this.fitBoundsGeojsonInMap(polyToZoom, 80)
      }
    },

    updateMarkers() {
      let vm = this
      var features = vm.map.querySourceFeatures('promy-cluster')
      const keepMarkers = []
      for (let i = 0; i < features.length; i++) {
        const coords = features[i].geometry.coordinates
        const props = features[i].properties
        const featureID = features[i].id
        const clusterID = props.cluster_id || null
        if (props.cluster && vm.markers.has('promy-cluster_' + clusterID)) {
          //Cluster marker is already on screen
          keepMarkers.push('promy-cluster_' + clusterID)
        } else if (props.cluster) {
          //This feature is clustered, create an icon for it and use props.point_count for its count
          let el = vm.createIconForCluster(props.point_count, coords, clusterID)
          keepMarkers.push('promy-cluster_' + featureID)
          vm.markers.set('promy-cluster_' + clusterID, el)
        } else if (vm.markers.has(featureID)) {
          //Feature marker is already on screen
          keepMarkers.push(featureID)
        } else {
          //Feature is not clustered and has not been created, create an icon for it
          let properties = features[i].properties

          let el = vm.createIconForLocalisation(properties, coords)
          vm.addListenerToMarkerLocalisation(el)
          keepMarkers.push(featureID)
          vm.markers.set(featureID, el)
        }
      }

      //Let's clean-up any old markers. Loop through all markers
      vm.markers.forEach((value, key, map) => {
        //If marker exists but is not in the keep array
        if (
          keepMarkers.indexOf(key) === -1 &&
          (value.getAttribute('key') !== vm.position_active.key ||
            !vm.position_active.key)
        ) {
          //Remove it from the page
          value.remove()
          //Remove it from markers map
          map.delete(key)
        }
      })
      if (vm.current_position) {
        let el_current_position = document.querySelector(
          `[coords_poi="[${vm.current_position.coords[0]},${vm.current_position.coords[1]}]"]`,
        )

        if (el_current_position) {
          el_current_position.click()
          this.$store.commit('localisation/CURRENT_POSITION', null)
        }
      }
    },

    remove_add_layer_route_line() {
      this.removeSourceAndLayers(...this.source_and_layers_route_driving)
      this.removeSourceAndLayers(...this.source_and_layers_route_walking)
    },
    addLineWalkingToBeFirst() {
      if (
        this.isExist(this.map.getLayer(this.source_and_layers_route_walking[2]))
      ) {
        this.map.removeLayer(this.source_and_layers_route_walking[2])
        this.addOutlineLayerToGeojson(
          this.source_and_layers_route_walking[2],
          this.source_and_layers_route_walking[0],
          this.orange,
          true,
        )
      }
    },
    add_layer_route_line(profile, coords_marker) {
      let cuurent_marker = this.$turf.point(coords_marker)
      let feature_collection = this.$turf.featureCollection([
        cuurent_marker,
        ...this.allParcelles,
        this.line_route,
      ])
      let enveloped = this.$turf.envelope(feature_collection)

      let source_layers =
        profile === 'driving'
          ? this.source_and_layers_route_driving
          : this.source_and_layers_route_walking
      let dash = profile === 'driving' ? false : true
      let color = profile === 'driving' ? this.green_light : this.orange
      this.removeSourceAndLayers(...source_layers)
      this.addGeojsonToMap(source_layers[0], this.line_route)
      this.addOutlineLayerToGeojson(
        source_layers[2],
        source_layers[0],
        color,
        dash,
      )
      if (profile === 'driving') {
        let padding_bounds = screen.width < 768 ? 100 : 130
        this.fitBoundsGeojsonInMap(enveloped, padding_bounds)
      }

      this.addLineWalkingToBeFirst()
    },
    getDirection(
      profile,
      end,
      start = this.informationsStore.center_parcelle_principale,
    ) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            `${this.$mapbox_directions}${profile}/${start[0]},${start[1]};${end[0]},${end[1]}?geometries=geojson&overview=full&access_token=${this.$key_mapbox}`,
          )
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            this.$toaster.warning('itineraire non disponible')
          })
      })
    },

    addAllLocalisations(items) {
      let vm = this
      this.points = []
      this.multiPt = []
      this.sliceOption()
      this.markers = new Map()
      this.allMarkersLocalisations.forEach(function (marker) {
        marker.remove()
      })
      this.allMarkersLocalisations = []
      items.forEach((item) => {
        vm.addMarkersSelected(item)
      })
      this.addCluster()
      this.updateMarkers()
    },
    addListenerToMarkerLocalisation(el) {
      let vm = this
      el.addEventListener('click', function (e) {
        let coords_marker = e.target.getAttribute('id').split(',')
        vm.position_active.marker = e.target.getAttribute('marker')
        vm.position_active.label = e.target.getAttribute('label')
        vm.position_active.subCat = e.target.getAttribute('subCat')
        vm.position_active.key = e.target.getAttribute('key')
        vm.profiles.forEach((profile) => {
          vm.getDirection(profile, coords_marker).then((response) => {
            let route = response.data.routes[0]
            vm.line_route = vm.$turf.lineString(route.geometry.coordinates)
            if (profile === 'driving') {
              vm.$store.commit('localisation/DRIVING', route.legs[0])
            } else {
              vm.$store.commit('localisation/WALKING', route.legs[0])
            }

            vm.add_layer_route_line(profile, coords_marker)

            var parent_scroll = document.getElementById('scroll_tools')
            var element_scroll_bati = document.getElementById(
              'positions-selectionnee',
            )
            setTimeout(function () {
              var topPos = element_scroll_bati.offsetTop
              vm.scrollTo(parent_scroll, topPos - 300, 100)
            }, 500)
          })
        })
      })
    },
    loadLocalisations(e) {
      if (e.sourceId !== 'promy-cluster') return
      this.map.off('moveend', this.updateMarkers)
      this.map.on('moveend', this.updateMarkers)

      this.updateMarkers()
    },

    findLocalisation(localisations, current_localisation) {
      return localisations.find((element) => {
        let temp_elmenet = element.subKeys.map((item) => item.key)

        return temp_elmenet.includes(current_localisation.categorie)
      })
    },
    fitBoundsPoint(point) {
      var buffered = this.$turf.buffer(point, 0.1, { units: 'miles' })
      this.fitBoundsGeojsonInMap(buffered)
    },
  },

  watch: {
    selected(items) {
      this.addAllLocalisations(items)
    },
    current_position: {
      deep: true,
      handler(newPosition) {
        if (newPosition) {
          var element_to_scroll = document.getElementById('map_localisation')
          setTimeout(function () {
            var topPos = element_to_scroll.offsetTop
            window.scrollTo(0, topPos)
          }, 500)

          if (!this.findLocalisation(this.selected, newPosition)) {
            this.selected.push(this.findLocalisation(this.types, newPosition))
            this.addAllLocalisations(this.selected)
          } else {
            this.fitBoundsPoint(this.$turf.point(this.current_position.coords))
          }
        }
      },
    },
  },
  mounted() {
    this.selected = this.types
    this.map.on('data', this.loadLocalisations)
  },
  beforeDestroy() {
    this.allMarkersLocalisations.forEach(function (marker) {
      marker.remove()
    })
    this.$store.commit('localisation/CURRENT_POSITION', null)
    if (this.map) {
      this.map.off('moveend', this.updateMarkers)
      this.map.off('data', this.loadLocalisations)
    }
  },
}
</script>

<style>
.marker_cat {
  @apply bg-cover cursor-pointer;
  width: 29px;
  height: 43px;
}
.mapCluster {
  @apply flex text-black justify-center text-base items-center font-bold bg-white font-main w-10 h-10 border-solid  border-promy-green-300 border-4;
  border-radius: 50%;
}
</style>
